<template>
  <list-template
      :loading="loading"
      :total="total"
      :current-page="page"
      :table-data="tableData"
      :table-config="tableConfig"
      :search-config="searchConfig"
      @onChangePage="changeCurrentPage"
      @onSearch="onSearch"
      @onHandle="tableHandle"
      @onChange="onChange"
      @onReset="onReset"
      :reset-no-request="true"
      ref="table"
      :empty_text="empty_text"
  >
  </list-template>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import { setConfigValue } from '@/utils'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["userInfo"])
  },
  data() {
    return {
      loading: false,
      empty_text: '请选择筛选条件，并搜索',
      // 搜索框的选中数据
      search: { },
      // 数据总量
      total: 0,
      searchConfig: [
        {
          prop: "month",
          tag: "datePicker",
          placeholder: "请选择年月",
          property: {
            size: "small",
            style: "margin-right:20rem;",
            type: "month",
            valueFormat: "yyyyMM",
          }
        },
        {
          prop: "status",
          placeholder: "请选择完成状态",
          tag: "select",
          options: [
            {label: "已完成", value: 1},
            {label: "未完成", value: 2}
          ]
        },
        {
          prop: "subject_id",
          placeholder: "请选择科目",
          tag: "select",
          options: [],
          label: 'name',
          value: 'subject_id',
          change: (val, search) => {
            if (search.school_id) {
              this.search = search
              let params = {
                subject_id: search.subject_id,
                school_id: search.school_id
              }
              if (search.grade_id) {
                params.grade_id = search.grade_id
              }
              this.$_axios2.get('api/common/school', {params}).then(res => {
                let data = res.data.data;
                this.searchConfig[5].options = data.teaching_data;
                this.searchConfig[6].options = data.class;
              })
            }
          }
        },
        {
          prop: 'school_id',
          tag: 'select',
          placeholder: '请选择校区',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = search
            this.search.grade_id = ''
            this.search.teaching_id = ''
            let params = {
              school_id: search.school_id
            }
            if (search.subject_id) {
              params.subject_id = search.subject_id
            }
            this.$_axios2.get('api/common/school', {params}).then(res => {
              let data = res.data.data;
              this.searchConfig[4].options = data.grade;
              this.searchConfig[5].options = data.teaching_data;
              this.searchConfig[6].options = data.class;
              if (data.subject_data.length) {
                let boo = false
                data.subject_data.forEach(item => {
                  if (item.subject_id === search.subject_id) boo = true
                })
                if (!boo) {
                  this.search.subject_id = ''
                }
              } else {
                this.search.subject_id = ''
              }
            })
          }
        },
        {
          prop: 'grade_id',
          tag: 'select',
          placeholder: '请选择年级',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = search
            this.search.teaching_id = ''
            let params = {
              school_id: search.school_id,
              grade_id: search.grade_id
            }
            if (search.subject_id) {
              params.subject_id = search.subject_id
            }
            this.$_axios2.get('api/common/school', {params}).then(res => {
              let data = res.data.data;
              this.searchConfig[2].options = data.subject_data;
              this.searchConfig[5].options = data.teaching_data;
              this.searchConfig[6].options = data.class;
              if (data.subject_data.length) {
                let boo = false
                data.subject_data.forEach(item => {
                  if (item.subject_id === search.subject_id) boo = true
                })
                if (!boo) {
                  this.search.subject_id = ''
                }
              } else {
                this.search.subject_id = ''
              }
            })
          }
        },
        {
          prop: 'teaching_id',
          tag: 'select',
          placeholder: '请选择教学班',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            if (!search.subject_id) {
              this.search = search
              let params = {
                school_id: search.school_id,
                grade_id: search.grade_id,
                teaching_id: search.teaching_id,
              }
              this.$_axios2.get('api/common/school', {params}).then(res => {
                let data = res.data.data;
                this.searchConfig[2].options = data.subject_data;
                if (data.subject_data.length) {
                  let boo = false
                  data.subject_data.forEach(item => {
                    if (item.subject_id === search.subject_id) boo = true
                  })
                  if (!boo) {
                    this.search.subject_id = ''
                  }
                } else {
                  this.search.subject_id = ''
                }
              })
            }
          }
        },
        {
          prop: 'class_id',
          tag: 'select',
          placeholder: '请选择行政班',
          options: [],
          label: 'name',
          value: 'id',
        },
        {
          prop: "keyword",
          placeholder: "请输入学生姓名搜索",
          default: this.$route.query.student_name
        },
      ],
      tableData: [],
      tableConfig: [
        {width: "80rem", label: '序号', type: 'number', textAlign: 'left', fixed: true},
        {
          prop: "student_name", label: "学生姓名", minWidth: 100, fixed: true, render: (row) => {
            return row.student_info.student_name
          }
        },
        {
          prop: "student_no", label: "学号", render: (row) => {
            return row.student_info.student_no
          }
        },
        {prop: "month", label: "检查年月", minWidth: 100},
        {prop: "teacher_info", label: "检查老师", minWidth: 100},
        {prop: "subject_info", label: "检查科目", minWidth: 100},
        {prop: "teaching_info", label: "教学班"},
        {prop: "class_info", label: "行政班"},
        {prop: "grade_info", label: "年级"},
        {prop: "school_info", label: "校区"},
        {prop: "check_time", label: "检查时间", minWidth: 100},
        {prop: "status_name", label: "完成状态", minWidth: 100, fixed: 'right'},
        {
          prop: "",
          label: "操作",
          fixed: 'right',
          handle: true,
          showOverflowTooltip: false,
          buttons: [{ type:"view", text:"详情" }]
        },
      ],
    }
  },
  mounted() {
    this.init();
  },
  activated() {
    this.init();
  },
  methods: {
    ...mapMutations(["setPage"]),
    onChange(val) { this.search = val },
    init() {
      this.$store.commit("setPage", 1);
      this.commonInterface(); // 获取可筛选项
      const name = this.$route.query.student_name
      if (name) {
        setConfigValue({ list: this.searchConfig, key: 'keyword', changeKey: 'default', value: name })
        this.search.keyword = name
        this.getData();
      }
    },
    commonInterface() {
      this.$_axios2.get('api/common/school').then(res => {
        if (res.data.status === 0) {
          this.searchConfig[2].options = res.data.data.subject_data;
          this.searchConfig[3].options = res.data.data.school;
        }
      })
    },
    // 获取列表基础数据
    getData() {
      if (this.loading) return
      this.loading = true
      this.$_axios2.get("/api/educational-routine/student-routine-list", {
        params: {
          ...this.search,
          page: this.page
        }
      }).then(res => {
        let data = res.data.data
        if (!(Array.isArray(data))) {
          this.tableData = data.list
          this.total = data.page.total
        } else {
          this.tableData = []
        }
      }).catch().finally(() => {
        this.loading = false
        this.empty_text = '暂无数据'
      })
    },
    changeCurrentPage(e) {
      this.setPage(e)
      this.getData();
    },
    onSearch(val) {
      this.setPage(1);
      this.search = val
      this.getData();
    },
    // 表格后面操作被点击
    tableHandle(row, text) {
      this.$router.push({path: "./details", query: {id: row.id}});
    },

    onReset() {
      this.commonInterface()
      this.empty_text = '请选择筛选条件，并搜索'
      this.tableData = []
      this.searchConfig[4].options = []
      this.searchConfig[5].options = []
      this.searchConfig[6].options = []
      setConfigValue({ list: this.searchConfig, key: 'keyword', changeKey: 'default' })
      this.search = {}
    }
  }
}
</script>

<style scoped>

</style>
